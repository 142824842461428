<template>
  <loading v-if="isLoading" />
  <div v-else>
    <b-loading :active="isReloading" :is-full-page="false" />
    <b-table
      id="payment-methods"
      :backend-sorting="true"
      :data="mappedCards"
      :hoverable="context === 'manage'"
      :mobile-cards="false"
      :row-class="isSelected"
      :class="{ 'is-selectable': context === 'select' }"
      class="has-vertical-align-middle"
      @click="selectCard"
    >
      <template #empty>
        <no-results
          :cta="
            ['manage', 'select'].includes(context)
              ? { label: 'Add card', class: 'is-info' }
              : {}
          "
          icon="credit-card"
          title="No cards on file"
          message="You don't have any saved cards."
          @click="addNewCard"
        />
      </template>

      <template #default="{ row: card }">
        <b-table-column custom-key="card-icon" width="56">
          <img :src="card.logo" class="is-block" />
        </b-table-column>

        <b-table-column custom-key="card" class="has-padding-x-0">
          <div
            class="is-spaced-apart has-flex-wrap"
            :style="{ gap: '0.25rem' }"
          >
            <div>
              <p class="is-size-7">
                <strong>{{ `${card.name} (${"••••"} ${card.last4})` }}</strong>
              </p>
              <p class="is-size-8 has-text-grey">
                Expires {{ card.exp_month }}/{{ card.exp_year }}
              </p>
            </div>

            <template v-if="card.isExpired">
              <b-tag type="is-danger">Expired</b-tag>
            </template>
            <template v-else-if="card.isPreferred">
              <b-tag type="is-info">Preferred</b-tag>
            </template>
          </div>
        </b-table-column>

        <template v-if="context === 'manage'">
          <b-table-column :width="48" custom-key="manage">
            <b-dropdown position="is-bottom-left" :mobile-modal="false">
              <template #trigger>
                <b-button icon-right="ellipsis-v" />
              </template>

              <b-dropdown-item
                :disabled="card.isPreferred || card.isExpired"
                @click="setPreferredMethod(card)"
              >
                Set as preferred method
              </b-dropdown-item>

              <b-dropdown-item
                class="has-text-danger"
                @click="deletePaymentMethod(card)"
              >
                Delete payment method
              </b-dropdown-item>
            </b-dropdown>
          </b-table-column>
        </template>
      </template>
    </b-table>

    <p
      v-if="mappedCards.length && context !== 'view'"
      class="has-margin-top-100"
    >
      <button type="button" class="button" @click="addNewCard">
        <b-icon icon="plus-circle" size="is-small" />

        <span>Add card</span>
      </button>
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PaymentMethods",
  props: {
    userId: {
      required: true,
      validator: value => {
        return ["string", "null"].includes(typeof value);
      }
    },
    selectedCardId: {
      default: null,
      validator: value => {
        return ["string", "null"].includes(typeof value);
      }
    },
    context: {
      default: "view",
      type: String,
      validator: value => {
        return ["view", "select", "manage"].indexOf(value) !== -1;
      }
    }
  },
  data() {
    return {
      isLoading: true,
      isReloading: false,
      overrideSelectedCardId: null,
      paymentMethods: [],
      savedCards: {},
      selected: null
    };
  },
  computed: {
    ...mapState({
      width: state => state.ui.window.width
    }),
    user() {
      return this.$store.getters["user/user"](this.userId);
    },
    mappedCards() {
      return this.$_(this.paymentMethods)
        .map(({ card, id }) => {
          const expiryDate = this.$moment()
            .year(card.exp_year)
            .month(card.exp_month - 1) // Account for 0 index
            .endOf("month");

          const brand = (card.brand || "").replace(" ", "_").toLowerCase();
          const logo = this.getProvider(brand).image;

          return {
            ...card,
            id,
            isExpired: expiryDate.toDate() <= new Date(),
            isPreferred: this.user.stripePreferredPaymentMethodId === id,
            logo: `/assets/images/interface/payment-providers/${logo}`,
            name: this.getProvider(brand).name
          };
        })
        .value();
    }
  },
  async created() {
    if (!this.userId) return;
    try {
      await Promise.all([
        this.loadPaymentMethods(),
        this.consumeSetupIntentQueryParams()
      ]);
      if (this.context === "select") this.makeDefaultSelection();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async loadPaymentMethods() {
      try {
        const { data } = await this.$store.dispatch(
          "billing/getPaymentMethods",
          {
            userId: this.userId
          }
        );
        this.paymentMethods = data;
      } catch (error) {
        console.error(error);
      }
    },

    async reloadPaymentMethods() {
      this.isReloading = true;
      await this.loadPaymentMethods();
      this.isReloading = false;
    },

    getProvider(brand) {
      switch (brand) {
        case "american_express":
        case "amex":
          return {
            name: "Amex",
            image: "amex.svg"
          };
        case "maestro":
          return { name: "Maestro", image: "maestro.svg" };
        case "mastercard":
          return { name: "Mastercard", image: "mastercard.svg" };
        case "visa":
          return { name: "Visa", image: "visa.svg" };
        default:
          return { name: brand, image: "default.svg" };
      }
    },

    async consumeSetupIntentQueryParams() {
      try {
        const { setup_intent_client_secret } = this.$route.query;
        if (!setup_intent_client_secret) return;
        // Remove given params from query
        this.$router
          .replace({
            query: this.$_.omit(this.$route.query, [
              "setup_intent",
              "setup_intent_client_secret",
              "redirect_status"
            ])
          })
          .catch(err => err);
        // eslint-disable-next-line no-undef
        const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
        const { setupIntent } = await stripe.retrieveSetupIntent(
          setup_intent_client_secret
        );
        if (setupIntent.status === "succeeded") {
          this.overrideSelectedCardId = setupIntent.payment_method;
          this.$toast.open({
            message: "Payment method successfully added",
            type: "is-success"
          });
        }
      } catch {
        // Fail silently
      }
    },

    makeDefaultSelection() {
      if (this.selected) return;
      this.selectCard(
        this.$_.find(
          this.mappedCards,
          ({ id }) =>
            id ===
            (this.overrideSelectedCardId ||
              this.selectedCardId ||
              this.user.stripePreferredPaymentMethodId)
        ) || this.$_.first(this.mappedCards)
      );
      this.overrideSelectedCardId = null;
    },

    setPreferredMethod(card) {
      this.$store
        .dispatch("user/updateProfile", {
          userId: this.userId,
          payload: { stripePreferredPaymentMethodId: card.id }
        })
        .then(() => {
          this.$toast.open({
            message: `Preferred payment method updated`
          });
        })
        .catch(error => {
          // this.isProcessing = false;
          // this.focusOnName();
          if (error && error.message) {
            this.$toast.open({
              message: `${error.message}`,
              type: "is-danger"
            });
          }
        });
    },
    deletePaymentMethod(card) {
      this.$store
        .dispatch("billing/deletePaymentMethod", {
          id: card.id,
          last4: card.last4,
          brand: card.brand,
          userId: this.userId
        })
        .then(result => {
          this.reloadPaymentMethods();
          this.$toast.open({
            message: result.message
          });
        })
        .catch(error => {
          this.$toast.open({
            message: `${error.message}`,
            type: "is-danger"
          });
        });
    },
    addNewCard() {
      // If impersonation
      if (
        !this.$store.getters["auth/isCurrentUser"](this.userId) &&
        !this.$store.getters["user/isAdmin"]()
      ) {
        this.$toast.open({
          message: `Invalid permissions`,
          type: "is-danger"
        });
      } else {
        const vm = this;
        const addCardModal = this.$modal.open({
          component: () => import("@shared/account/_addCardModal"),
          parent: this.$root,
          width: 460,
          hasModalCard: true,
          canCancel: ["button"],
          props: {
            userId: this.userId
          },
          events: {
            async success({ payment_method }) {
              addCardModal.close();
              await vm.reloadPaymentMethods();
              vm.$nextTick(() => {
                vm.selectCard(
                  vm.$_.find(vm.mappedCards, ({ id }) => id === payment_method)
                );
              });
            }
          }
        });
      }
    },
    selectCard(card) {
      if (this.context !== "select") return;
      if (!card) return;
      if (card.isExpired)
        return this.$toast.open({
          message: "This card has expired",
          type: "is-danger"
        });
      this.selected = this.$_.isEqual(this.selected, card) ? null : card;
      this.$emit("selected", this.selected);
    },
    isSelected({ id }) {
      return this.selected && this.selected.id === id
        ? "is-selected-custom"
        : "";
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";
#payment-methods {
  thead th {
    padding: 0;
    border-width: 0;
  }
}
</style>
